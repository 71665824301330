"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.init = void 0;

var _functions = require("./Utils/functions");

var _this = void 0;

var render = function render(config, store, elem) {
  if (!elem) {
    return;
  }

  import('./Views/Components/DealsWidget/DealsWidgetContainer').then(function (exports) {
    (0, _functions.renderComponent)(exports.default, store, config, elem);
  });
};

var loadComponents = (0, _functions.loaderSimpleComponent)(function () {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }

  render.apply(_this, args);
});

var bannerInit = function bannerInit() {
  for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    args[_key2] = arguments[_key2];
  }

  var store = args[0],
      schemaName = args[1],
      elem = args[2],
      sourceIdentifier = args[3];
  var state = store.getState();
  var schema = state.schema;
  var moduleConfig = schema.get(sourceIdentifier);
  loadComponents(moduleConfig, schemaName, elem, store);
};

var init = bannerInit;
exports.init = init;